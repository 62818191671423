// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyBFtHH0Cg_1pLFmOQQXfLvsF2HwlkZP0gU",
    authDomain: "medicine-c2e09.firebaseapp.com",
    projectId: "medicine-c2e09",
    storageBucket: "medicine-c2e09.appspot.com",
    messagingSenderId: "69358126837",
    appId: "1:69358126837:web:410c08c1c9b2951204e7bc"
  },
  production: false,
  pkt: "sk_live_cd8b9699510504f4bc017d8d2543662d60829a89",
  e_key: "AIzaSyAQhOMt2uvM5NAGfrP8XKsELuqUHwDHjzQ"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
