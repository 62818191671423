export const environment = {
  firebase: {
    apiKey: "AIzaSyBFtHH0Cg_1pLFmOQQXfLvsF2HwlkZP0gU",
    authDomain: "medicine-c2e09.firebaseapp.com",
    projectId: "medicine-c2e09",
    storageBucket: "medicine-c2e09.appspot.com",
    messagingSenderId: "69358126837",
    appId: "1:69358126837:web:410c08c1c9b2951204e7bc"
  },
  production: true,
  pkt: "sk_live_cd8b9699510504f4bc017d8d2543662d60829a89",
  e_key: "AIzaSyAQhOMt2uvM5NAGfrP8XKsELuqUHwDHjzQ"
};
